export const RestEndpoint = {
    authenticateUser: '/api/v1/authentication/authenticateUser',
    publicRsa: '/api/v1/authentication/getPublicKeyRsa',
    getAllUsers: '/api/v1/authentication/getAllUsers',
    changeBu: '/api/v1/authentication/changeBu',
    getCrowdfundingCompanyDetails: '/api/v1/crowdfunding-details/getCrowdfundingCompanyDetails',
    getAllCrowdfundingCategories: '/api/v1/crowdfunding/getCrowdfundingCategoriesList',
    getAllCrowdfundingBanking: '/api/v1/crowdfunding-banking/getAll',
    updateDocuments: '/api/v1/authentication/updateDocuments',
    UpdateMyGenericKYCDocuments: '/api/v1/authentication/UpdateMyGenericKYCDocuments',
    createComment: '/api/v1/crowdfunding/comments',
    getCrowdfundings: '/api/v1/crowdfunding/getCrowdfundingList',
    getCrowdfunding: '/api/v1/crowdfunding/getCrowdfunding',
    updateUserAcceptedTerms: '/api/v1/crowdfunding/updateUserAcceptedTerms',
    createCrowdfundingCheckoutOrder: '/api/v1/crowdfunding/createCrowdfundingCheckoutOrder',
    performPaymentWithCrypto: '/api/v1/crowdfunding/performPaymentWithCrypto',
    getTransactionReceiptById: '/api/v1/bank-accounts/getTransactionById',
    getAllUserOders: '/api/v1/crowdfunding/getAllUserOders',
    createLoanRequests: '/api/v1/authentication/createLoanRequests',
    getAllLoanRequests: '/api/v1/authentication/getAllLoanRequests',
    sendBenefitsEmail: '/api/v1/authentication/sendBenefitsEmail',
    getMyDocuments: '/api/v1/authentication/getMyDocuments',
    getMyKYCDocumentsStatus: '/api/v1/authentication/getMyKYCDocumentsStatus',
    deleteFriends: '/api/v1/friends/friend/',
    createFriends: '/api/v1/friends/createFriends',
    mainWalletBalance: 'api/v1/financial/mainWalletBalance',
    getMinToBuy: '/api/v1/collections/getMinToBuy',
    getSumUsedByCollection: '/api/v1/collections/getSumUsedByCollection',
    getAllEniatoBanners: '/api/v1/banners/getAllEniatoBanners',
    getAllCrowdfundingBanners: '/api/v1/banners/getAllCrowdfundingBanners',
    getAllMidasBanners: '/api/v1/banners/getAllMidasWebBanners',
    getAllExchangeBanners: '/api/v1/banners/getAllExchangeBanners',
    getAllPaymentQueuesTimelinesView: '/api/v1/assets/getAllPaymentQueuesTimelinesView',
    getCountForAssets: '/api/v1/balance/getDepositsForNFTs',
    updateFriend: '/api/v1/friends/updateFriend',
    getConfigForIN1888: '/api/v1/config-in1888/readAll',
    sendContactEmail: '/api/v1/authentication/sendContactEmail',
    updateFavorite:  '/api/v1/products-favorites/updateFavorite',
    countFavorites:  '/api/v1/products-favorites/countFavorites',
    createFavorite:  '/api/v1/products-favorites/createtoFavorite',
    getEniatoConfig: '/api/v1/eniato-config/readAll',
    getStore: '/api/v1/stores/getStore',
    getCollections : '/api/v1/collections/getAllCollections',
    getCollectionStatistics : 'api/v1/collections/getCollectionStatistics',
    getAllCollectionsStatistics : 'api/v1/collections/getAllCollectionsStatistics',
    createWatch:  '/api/v1/products-favorites/createWatch',
    createUser: '/api/v1/authentication/createuser',
    getBTCWallet: '/api/v1/authentication/getBTCWallet',
    resendConfirmationCode: '/api/v1/authentication/resendConfirmationCode',
    sendMFACode: '/api/v1/authentication/sendMFACode',
    getDecimalPlaces: '/api/v1/fiatCurrency/getDecimalPlaces',
    destroySession: '/api/v1/authentication/destroySession',
    files: '/api/v1/files',
    friends: '/api/v1/friends/getAllFriends',
    lostpassword: '/api/v1/authentication/lostpassword',
    getImageStorageParameters: '/api/v1/images/getImageStorageParameters',
    translatableTexts: '/api/v1/translate/getTranslatableTexts',
    changelostpassword: '/api/v1/authentication/changelostpassword',
    isAuthenticated: '/api/v1/authentication/isAuthenticated',
    verifyDocument: '/api/v1/authentication/validateDocument',
    changeNewsLetter: '/api/v1/authentication/changeNewsLetter',
    getAllProducts: '/api/v1/products/getAllProducts',
    searchProducts: '/api/v1/products/searchProducts',
    verifyMFAStatus: '/api/v1/authentication/verifymfastatus',
    startMFAProcess: '/api/v1/authentication/startmfaprocess',
    finalizeMFAProcess: '/api/v1/authentication/finalizemfaprocess',
    getMinAmountToTransfer: '/api/v1/financial/getMinAmountToTransfer',
    balance: '/api/v1/financial/plataformbalance',
    allQuotations: '/api/v1/authentication/allQuotations',
    verify: '/api/v1/financial/verify',
    transfer: '/api/v1/financial/transfer',
    createUserStake: '/api/v1/staking/createUserStake',
    createSignRequest: '/api/v1/staking/createSignRequest',
    cancelUserStake: '/api/v1/staking/cancelUserStake',
    getAllOrders: '/api/v1/orders/user',
    getOrder: '/api/v1/orders/order/',
    changeOrderStatus: '/api/v1/orders/updateInformation/',
    CheckClearLedgerPaymentStatus: '/api/v1/orders/CheckClearLedgerPaymentStatus/',
    CheckCelcoinIntegrationPaymentStatus: '/api/v1/orders/CheckCelcoinIntegrationPaymentStatus/',
    checkPixPaymentStatus: '/api/v1/orders/checkPixPaymentStatus/',
    createProduct: '/api/v1/products/createProduct',
    updateProducts: '/api/v1/products/updateProducts',
    getProduct: '/api/v1/products/getProduct',
    deleteProduct: '/api/v1/products/product/',
    getAllCategories: '/api/v1/categories/getAllCategories',
    getAllEventsCategories: '/api/v1/events-categories/getAllCategories',
    createCategory: '/api/v1/categories/createCategory',
    updateCategories: '/api/v1/categories/updateCategories',
    deleteCategory: '/api/v1/categories/category/',
    deleteCollections: '/api/v1/collections/collection/',
    getAllParameters: '/api/v1/parameters/getAllParameters',
    getUserAddress: '/api/v1/location/getAll',
    createUserAddress: '/api/v1/location/createUserAddress',
    createUserAdditional: '/api/v1/user-additional/createUserAdditional',
    getAllUserAdditional: '/api/v1/user-additional/getAll',
    updateUserAddress: '/api/v1/location/updateUserAddress',
    getParameter: '/api/v1/parameters/getParameter',
    deleteParameter: '/api/v1/parameters/parameter/',
    shoppingCarts: '/api/v1/shopping-carts/',
    shoppingCartsEvents: '/api/v1/events-shopping-carts/',
    quotations: '/api/v1/authentication/quotations',
    quotationsHistory: '/api/v1/authentication/readRateHistory',
    fastPrice: '/api/v1/swap/price',
    performSwap: '/api/v1/swap',
    getAllSwapPairs: '/api/v1/tokens/getAllSwapPairs',
    getAllSwapCriptoToken: '/api/v1/tokens/getAllSwapCriptoToken',
    createParameter: '/api/v1/parameters/createParameter',
    updateParameters: '/api/v1/parameters/updateParameters',
    createCheckout: '/api/v1/checkout',
    createExternalCheckout: '/api/v1/checkout/external',
    updateUserBasicInfos: '/api/v1/authentication/updateBasicInfos',
    // Eniato assets
    markets: '/api/market/v1/getMarket',
    getAssets: '/api/v1/assets/getAssets',
    getNetworks : '/api/v1/networks/getNetworks',
    getDetails: '/api/v1/assets/getAsset',
    getAsset: '/api/v1/eniato-assets/getAsset',
    getCollection: '/api/v1/collections/getCollection',
    getUserTransactions: '/api/history/v1',
    createMarket: '/api/market/v1/createMarket',
    createAsset: '/api/v1/assets/createAsset',
    createCollections: '/api/v1/collections/createCollection',
    updateCollections: '/api/v1/collections/updateCollections',
    editAsset: '/api/v1/assets/updateAsset',
    resellAsset: '/api/v1/assets/resellAsset',
    createAuction: '/api/v1/assets/createAuction',
    createBid: '/api/v1/assets/createBid',
    acceptHigherBid: '/api/v1/assets/acceptHigherBid',
    finishAuction: '/api/v1/assets/finishAuction',
    cancelAuction: '/api/v1/assets/finishAuction',
    cancelMyBid: '/api/v1/assets/cancelMyBid',
    getUserDeposits: '/api/v1/balance/getUserDeposits',
    journal: '/api/v1/financial-internal/general-journey',
    createCorporateEscrow: '/api/v1/corporate-escrow/create',
    getCorporateEscrows: '/api/v1/corporate-escrow/getAll',
    getCorporateEscrow: '/api/v1/corporate-escrow/getById',
    deleteEscrow: '/api/v1/corporate-escrow/deleteEscrow',
    updateCorporateEscrow: '/api/v1/corporate-escrow/updateCorporateEscrow',
    getMyTransactionsReport: '/api/v1/financial-internal/get-my-transactions-report',
    getUserStakes: '/api/v1/staking/getUserStakes',
    getMyTransactionHistory: '/api/v1/financial-internal/my-transaction-history-admin',
    getAllowedStartDateTransactionsReport: '/api/v1/financial-internal/getAllowedStartDateTransactionsReport',
    getStakingConfig: '/api/v1/staking/getStakingConfig',
    checkDocumentStatus: '/api/v1/staking/checkDocumentStatus',
    createDepositRequest: '/api/v1/balance/createDepositRequest',
    getUserWithdrawal: '/api/v1/balance/getUserWithdrawal',
    getAllWithdrawalRequest: '/api/v1/balance/getAllWithdrawalRequest',
    getUserWithdrawalFavorites: '/api/v1/balance/getUserWithdrawalFavorites',
    getListOfBanks: '/api/v1/balance/getListOfBanks',
    createWithdrawRequest: '/api/v1/balance/createWithdrawRequest',
    updateWithdrawRequest: '/api/v1/balance/updateWithdrawRequest',
    getCategories: '/api/v1/eniato-market/categories',
    getBanners: '/api/v1/eniato-market/banners',
    getEniatoBalance: '/api/v1/eniato-market/balance',
    sendNFTs: '/api/v1/eniato-market/sendNFTs',
    buyNFT: '/api/v1/eniato-market/buyNFT',
    splitNFTs: '/api/v1/eniato-market/splitNFTs',
    buyPackage: '/api/v1/eniato-market/buyPackage',
    createPackage: '/api/v1/packages/createNFTPackageConfig',
    getDetailsPackage: '/api/v1/packages/getPackage',
    updateDetailsPackage: '/api/v1/packages/updateNFTPackageConfig',
    getPackages: '/api/v1/packages/getPackages',
    getPackageTypes: '/api/v1/packages/getPackageTypes',
    getAllMidasWebBanners: '/api/v1/banners/getAllMidasWebBanners',
    getFiatCurrency: '/api/v1/fiatCurrency/getEniatoFiatCurrency',
    getCryptoCurrencies: '/api/v1/cryptocurrencies',
    getMyOrders: '/api/v1/order-book/getUserOrder',
    getAllUnitOrder: '/api/v1/order-book/getAllUnitOrder',
    getKrakenOrderBook: '/api/v1/order-book/getKrakenOrderBook',
    getKrakenTrades: '/api/v1/order-book/getKrakenTrades',
    addOrder: '/api/v1/order-book/addOrder',
    cancelOrder: '/api/v1/order-book/cancelOrder',
    getDataForExchange: '/api/v1/authentication/getDataForExchange',
    getAllHistory: '/api/v1/authentication/getAllHistory',
    getUserByWallet: '/api/v1/authentication/getUserByWallet',
    getUserByEmailWallet: '/api/v1/authentication/getUserByEmailWallet', 
    createUserPayroll: '/api/v1/company-charges/create',
    updateUserPayroll: '/api/v1/company-charges/update',
    getUserListPayroll: '/api/v1/company-charges',
    getBRLAKYCStatus: '/api/v1/brla/kycStatus',
    createPFLevel1: '/api/v1/brla/pf/level1',
    createPJLevel1: '/api/v1/brla/pj/level1',
    createPJLevel2: '/api/v1/brla/pj/level2',
    quote: '/api/v1/brla/quote',
    ConvertUSDToPixBRLA: '/api/v1/brla/ConvertUSDToPixBRLA',
    createPFLevel2: '/api/v1/brla/pf/level2',
    sendDocBRLA: '/api/v1/brla/pf/doc',
    validateUsername: '/api/v1/authentication/validateUsername',

    baseReplaceURL: '/replaceDocumentUrl/',

    CreateCharge: '/api/v1/bills/CreateCharge',
    CheckCharge: '/api/v1/bills/CheckCharge',

    getContractById: '/api/v1/contracts/getContractById',
    getContractsByCustomerId: '/api/v1/contracts/getContractsByCustomerId',
    updateContractByCustomer: '/api/v1/contracts/updateContractByCustomer',
    realizeManualPayment: '/api/v1/contracts/realizeManualPayment',

    deleteUserPayroll: 'api/v1/company-charges/delete',
    // Kairos
    getAllKairosBanners: '/api/v1/banners/getAllKairosBanners',
    getEventsBanners: '/api/v1/banners/getEventsBanners',
    getKairosConfig: '/api/v1/kairos-config/readAll',
    getShippingData: '/api/v1/products/getShippingData',
    readLocation: '/api/v1/location/getAll',
    getAllRequests: '/api/v1/letter-credit/getAllUserRequests',
    createRequest : '/api/v1/letter-credit/createRequest',
    updateRequest: '/api/v1/letter-credit/updateRequest',
    getRequest: '/api/v1/letter-credit/getRequest',
    assignToMe: '/api/v1/letter-credit/assignToMe',
    createDocument: '/api/v1/letter-credit/createDocument',
    deleteDocument: '/api/v1/letter-credit/document',
    // Eniato assets
    GetPlatformTaxes: '/api/v1/financial-internal/GetPlatformTaxes',
    CalculateUserTaxes: '/api/v1/financial-internal/CalculateUserTaxes',
    //Read feature status
    getFeatureStatus: '/api/v1/features/getFeatureStatus',
    getAllFeatureStatus: '/api/v1/features/getAllFeatureStatus',

    //Read external links
    getExternalLinks: '/api/v1/external-links/readAll',
    getContactDetails: '/api/v1/external-links/getContactDetails',
    getTransferDetails: '/api/v1/external-links/getTransferDetails',
    getDigitalBankingConfigs: '/api/v1/external-links/getDigitalBankingConfigs',
    getDigitalBankingFeatureAvailability: '/api/v1/external-links/getDigitalBankingFeatureAvailability',
    //NFT Staking Config
    getAllStakingCampaigns: '/api/v1/nft-staking/getAllStakingCampaigns',
    getAllAssetsForStakingCampaign: '/api/v1/nft-staking/getAllAssetsForStakingCampaign',
    getStakingCampaignDetails: '/api/v1/nft-staking/getStakingCampaignDetails',
    createStaking: '/api/v1/nft-staking/createStaking',
    removeFromStaking: '/api/v1/nft-staking/removeFromStaking',
    getSummary: '/api/v1/nft-staking/getSummary',
    getMyStakedNFTs: '/api/v1/nft-staking/getMyStakedNFTs',
    getAllNFTsAvailableForStaking: '/api/v1/nft-staking/getAllNFTsAvailableForStaking',
    getSummarizedStakingApplication: '/api/v1/nft-staking/getSummarizedStakingApplication',
    getMetamaskParameters: '/api/v1/metamask/getMetamaskParameters',
    //Permissions
    checkPermission: '/api/v1/access-control/checkPermission',
    getEniatoHeaderLinks: '/api/v1/ux-configs/getEniatoHeaderLinks',
    getOrderOfHomeSections: '/api/v1/ux-configs/getOrderOfHomeSections',
    createToken: '/api/v1/tokens/createToken',
    updateTokens: '/api/v1/tokens/updateTokens',
    getToken: '/api/v1/tokens/getToken',
    deleteToken: '/api/v1/tokens/token/',
    getAllTokens: '/api/v1/tokens/getAllTokens',
    searchTokens: '/api/v1/tokens/searchTokens',
    getPaymentConfigs: '/api/v1/payment-configs/getPaymentsConfigurations',
    nextWihdrawal: '/api/v1/tokens/nextWihdrawal',
    remainingTokenAmount: '/api/v1/tokens/remainingTokenAmount',
    withdrawTokens: '/api/v1/tokens/withdrawTokens',
    createEscrow: '/api/v1/escrows/create',
    createInvoice: '/api/v1/invoices/create',
    updateEscrow: '/api/v1/escrows/update',
    updateInvoice: '/api/v1/invoices/update',

    getEscrows: '/api/v1/escrows/getEscrows',
    getInvoices: '/api/v1/invoices/getInvoices',
    getUserStakingPermissions: '/api/v1/access-control/getUserStakingPermissions',
    /**
     * Banking
     */
    banking: {
        getAllDigitalBankingBanners: '/api/v1/banners/getAllDigitalBankingBanners',
        account: {
            create: '/api/v1/bank-accounts/create',
            getBalance: '/api/v1/bank-accounts/balance',
            getHistory: '/api/v1/bank-accounts/history',
            generateInternalTransferRequest: '/api/v1/bank-accounts/generateInternalTransferRequest',
            generateExternalTransferRequest: '/api/v1/bank-accounts/generateExternalTransferRequest',
            validatePassword: '/api/v1/bank-accounts/validatePassword',
            changePassword: '/api/v1/bank-accounts/changePassword',
            resendCode: '/api/v1/bank-accounts/resendCode',
            getAccountInfoNaturalPerson: '/api/v1/bank-accounts/getAccountInfoNaturalPerson',
            getAccountInfoLegalPerson: '/api/v1/bank-accounts/getAccountInfoLegalPerson',
            updateAccountInfoLegalPerson: '/api/v1/bank-accounts/updateAccountInfoLegalPerson',
        },
        bills: {
            getBillData: '/api/v1/bills/checkBill',
            payBill: '/api/v1/bills/payBill',
        },
        pix: {
            getPixKeys: '/api/v1/pix/getKeys',
            createPixKey: '/api/v1/pix/create',
            excludePixKey: '/api/v1/pix/exclude',
            createStaticQRCode: '/api/v1/pix/createStaticQRCode',
            getStaticQRCode: '/api/v1/pix/getStaticQRCode',
            getAccountInfoByPixKey: '/api/v1/pix/getAccountInfoByPixKey',
            transfer: '/api/v1/pix/transfer',
            getQRCodeDataByEmv: '/api/v1/pix/getQRCodeDataByEmv',
        },
    },
    updateTokenFavorite:  '/api/v1/tokens/updateFavorite',
    createTokenFavorite:  '/api/v1/tokens/createFavorite',

    getAllProductsEvents: '/api/v1/products-events/getAllEvents',
    searchProductsEvents: '/api/v1/products-events/searchEvents',
    getProductEvent: '/api/v1/products-events/getEvent',
    // chat
    createChat: '/api/v1/chats',
    getListChats: '/api/v1/chats/listCorrespondents',
    getListFullChat: '/api/v1/chats/listFullChat', 
    getListLastUnreadChatMessages: '/api/v1/chats/listLastUnreadChatMessages',
    chats: '/api/v1/chats/',
    flagReadInChat: '/flagRead',
    updateMessageInChat: '/api/v1/chats/updateMessage',
    deleteMessageInChat: '/api/v1/chats/deleteMessage',
    getListGroupByUserId: '/api/v1/groups/listGroupByUserId',
    createChatGroup: '/api/v1/groups',
    getChatGroupById: '/api/v1/groups/',
    updateChatGroup: '/api/v1/groups/',
    deleteChatGroup: '/api/v1/groups/',

    // Reward
    redeemReward: '/api/v1/reward/redeemReward'
};